import { BankCode, BonusType } from ".";

export interface User {
  uid: string;
  email: string;
  name: string;
}

export interface Response<T = any> {
  status: string;
  code: number;
  serviceCode: string;
  serviceMessage: string;
  data: T;
}

export interface Pagination<T> {
  page: number;
  pageSize: number;
  total: number;
  results: T[];
}

export type TransactionType = "DEPOSIT" | "WITHDRAW" | "REFUND";

export type DepositTransactionStatus =
  | "PENDING"
  | "REJECTED"
  | "COMPLETED"
  | "WAITING_TRANSFER"
  | "TRANSFER_BACK"
  | "CANCELLED";

export type WithdrawTransactionStatus = "PENDING" | "REJECTED" | "COMPLETED";
export type MatchingState = "AUTO" | "MANUAL";
export type AdjustType = "WRONG_SLIP" | "WRONG_USER" | "VOIDED_WRONG_USER" | "VOIDED_WRONG_SLIP";

export interface EditTransaction {
  createdAt: string;
  updatedAt: string;
  uid: string;
  transactionUid: string;
  editingByUid: string;
}

export interface Transaction {
  uid: string;
  adjustType: AdjustType;
  type: TransactionType;
  fromBankCode: BankCode | null;
  fromBankAccountName: string | null;
  fromBankAccountNumber: string | null;
  toBankCode: BankCode | null;
  toBankAccountName: string | null;
  toBankAccountNumber: string | null;
  amount: number;
  isBonus: boolean;
  coinAmount: number;
  transferredAt: string | null;
  createdAt: string;
  updatedAt: string;
  editingBy: User | null;
  editTransaction: EditTransaction | undefined;
  editedTime: string | null;
  note: string | null;
  latestActionAt: string;
  isSent: boolean; // NOTE : it means "it is sending"
}

export interface DepositTransaction extends Transaction {
  fromBankCode: BankCode;
  fromBankAccountName: string | null;
  fromBankAccountNumber: string;
  toBankCode: BankCode;
  toBankAccountName: string;
  toBankAccountNumber: string;
  transferredAt: string;
  status: DepositTransactionStatus;
  bonusType?: BonusType;
  matchingState: MatchingState;
  customerUid: string | null;
  customerCode: string | null;
  refund: Omit<RefundTransaction, "transactionRef"> | null;
}

export interface WithdrawTransaction extends Transaction {
  status: WithdrawTransactionStatus;
  matchingState: MatchingState;
  customerUid: string | null;
  customerCode: string | null;
  customerPhoneNumber: string | null;
}

export interface RefundTransaction extends Transaction {
  transactionRef: Omit<DepositTransaction, "refund">;
  status: DepositTransactionStatus | WithdrawTransactionStatus;
}

export enum PaymentGateway {
  AMB = "AMB",
  QR = "QR",
}

export enum PartyBrand {
  PIGSPIN = "PIGSPIN",
  PIGBET = "PIGBET",
}

export type PaymentSettingType = PaymentGateway.AMB | PaymentGateway.QR;

export interface PaymentSetting {
  uid: string;
  name: string;
  isActived: boolean;
  type: PaymentSettingType;
}

export enum GameStatus {
  active = "Active",
  inactive = "Inactive",
}

export type GameStatusTypes = GameStatus.active | GameStatus.inactive;

export interface Games {
  noIndex?: number;
  gameId: string;
  gameName: string;
  gameCode: string;
  logoUrl?: string;
  coverUrl?: string;
  status: GameStatusTypes;
  order?: number;
  isPopular?: boolean;
  isNew?: boolean;
  isNewCard?: boolean;
  isHighlight?: boolean;
  isLobbyBanner?: boolean;
  refType?: string;
  rowIndex?: number;
  featureOption?: boolean;
  reelType?: string;
  wildType?: string;
  volatility?: string;
  maxWin?: number;
  rtp?: number;
  actualRtp?: string | null;
  actualRtpType?: string;
  createdAt?: string;
}

export interface GameBrands {
  uid: string;
  name: string;
  providerName: string;
  providerUid: string;
  logo?: string;
  party: string;
  isActive: boolean;
  isUnderConstruction?: boolean;
  isHot?: boolean;
  isComingSoon?: boolean;
  updatedAt?: string;
  createdAt?: string;
  lastIsActiveUpdated?: string;
  adminName?: string;
  totalGame?: number;
}

export interface UpdateGameResponse {
  status?: string;
  order?: number;
  is_popular?: boolean;
  is_new?: boolean;
}

export interface UpdateGameBrandResponse {
  partyBrand: PartyBrand;
  isProvider?: boolean;
}

export interface TransactionLog {
  createdAt: string;
  created_at: string;
  remark: string;
  uid: string;
}

export interface DepositTransactionWithLogs extends DepositTransaction {
  logs: TransactionLog[];
}

export interface WithdrawTransactionWithLogs extends WithdrawTransaction {
  logs: TransactionLog[];
}

export interface RefundTransactionWithLogs extends RefundTransaction {
  logs: TransactionLog[];
}

export type PolymorphicTransaction = DepositTransaction | WithdrawTransaction | RefundTransaction;

export type PolymorphicTransactionWithLogs =
  | DepositTransactionWithLogs
  | WithdrawTransactionWithLogs
  | RefundTransactionWithLogs;

export function isDepositTransaction(
  transaction: PolymorphicTransaction
): transaction is DepositTransaction {
  return transaction.type === "DEPOSIT";
}

export function isWithdrawTransaction(
  transaction: PolymorphicTransaction
): transaction is WithdrawTransaction {
  return transaction.type === "WITHDRAW";
}

export function isRefundTransaction(
  transaction: PolymorphicTransaction
): transaction is RefundTransaction {
  return transaction.type === "REFUND";
}

export enum CoinWalletStatus {
  ACTIVE = "active",
  CONVERTED = "converted",
  USED = "used",
  REVOKED = "revoked",
  CANCELLED = "canceled",
  EXPIRED = "expired",
}

export interface CoinWallet {
  cashWalletTransaction: {
    transactionAmount: number;
  };
  createdAt: string;
  currentBalance: number;
  currentTurnover: number;
  expireAt: string;
  initialBalance: number;
  isActive: boolean;
  isConvertedToCash: boolean;
  name: string;
  promotionUid: string | null;
  status: CoinWalletStatus;
  totalTurnover: number;
  turnOverRatio: number;
  convertedToCashAmount: number;
  convertLimitAmount: number;
  uid: string;
  updatedAt: string;
}

export enum CoinWalletLogActionType {
  UPDATE = "UPDATE",
  REVOKE = "REVOKE",
}

export interface CoinWalletLog {
  uid: string;
  actionType: CoinWalletLogActionType;
  actionBy: User;
  createdAt: string;
  updatedAt: string;
}

export interface CoinWalletCreate {
  customerCode: string;
  expireAt: string;
  initialBalance: number;
  name: string;
  promotionUid: string | null;
  turnOverRatio: number;
  initial: boolean;
  isForceWithdraw: boolean;
  convertLimitAmount: number;
  convertLimitRatio: number;
}

export interface CoinWalletUpdate {
  currentBalance: number;
  expireAt: string;
  initialBalance: number;
  isActive: boolean;
  name: string;
  promotionUid: string | null;
  status: CoinWalletStatus;
  totalTurnover: number;
  turnOverRatio: number;
  walletId: string;
  isForceWithdraw: boolean;
  convertLimitRatio: number;
}

export interface Promotion {
  beginAt: string | null;
  bonusRatio: number;
  code: string;
  convertLimitAmount: number;
  convertLimitRatio: number;
  durationInHours: number;
  endAt: string | null;
  initialCoinAmount: number;
  isActive: boolean;
  isForceWithdraw: boolean;
  maximumBonus: number;
  name: string;
  order: number;
  turnoverRatio: number;
  type: string;
  uid: string;
}

export enum CustomerLogType {
  CREDIT = "Credit",
  DEBIT = "Debit",
  GAME = "Game",
  BONUS = "Bonus",
  VOID = "Void",
  CONVERT = "CONVERT",
  TICKET = "TICKET",
  TOURNAMENT = "TOURNAMENT",
  TIP_CREDIT = 'TIP_CREDIT',
  TIP_DEBIT = 'TIP_DEBIT',
  RAIN_CREDIT = 'RAIN_CREDIT',
  RAIN_DEBIT = 'RAIN_DEBIT',
  FREE_SPIN = 'FREE_SPIN',
  PG_FREE_SPIN = 'PG_FREE_SPIN',
  CASINO_COMMISSION_01_PERCENT = "CASINO_COMMISSION_01_PERCENT",
  CASINO_COMMISSION_LOSING_STREAK = "CASINO_COMMISSION_LOSING_STREAK",
  PIGHUNT = "PIGHUNT",
  CASINO_TIPS = "CASINO_TIPS",
}

export interface CustomerLog {
  afterBalance: number;
  amount: number;
  isBonus: boolean;
  bonusType?: BonusType;
  beforeBalance: number;
  betTransactionRefId: string;
  cashBalance: number;
  coinBonusAmount: null;
  coinBonusBalance: number;
  createdAt: string;
  createdByUid: string | null;
  deletedAt: string | null;
  gameName: string;
  payoutAmount: number;
  payoutTransactionRefId: string;
  transactionType: CustomerLogType;
  uid: string;
  updatedAt: string;
  updatedByUid: string | null;
  userCode: string;
  walletTransactionRefUid: string;
  turnOverAmount: number;
}

export interface CustomerGameStat {
  gameName: string;
  count: number;
}

export interface TrueWalletReportItem {
  reportId: string;
  createdDateTime: string;
  customerAccountName: string | null;
  customerAccountNumber: string | null;
  amount: number;
  pigAccountName: string | null;
  pigAccountNumber: string;
  loggingDateTime: string;
  type: string;
  title: string;
}

export interface QRPaymentReportItem {
  reportId: string;
  customerCode: string;
  requestAmount: number;
  generateAmount: number;
  customerBankCode: BankCode;
  customerAccountName: string | null;
  customerAccountNumber: string | null;
  pigBankCode: BankCode | null;
  pigAccountName: string | null;
  pigAccountNumber: string | null;
  bankPromptpayNo: string | null;
  qrCodeUrl: string;
  qrRefCode: string;
  expiredAt: string;
  createdDateTime: string;
  lastUpdatedDateTime: string;
  isTransferred: boolean;
}

export enum BankTransactionType {
  API = "API",
  SMS = "SMS",
  IBANK = "IBANK",
  MBANK = "MBANK",
  MANUAL = "MANUAL",
}

export enum Channel {
  KPlus = "K PLUS",
  IBankMBank = "Internet/Mobile Across Banks",
  MakeByKBank = "MAKE by KBank",
  Empty = "",
  LineBk = "LINE BK",
}

export enum QuestType {
  GAME = "game",
  DEPOSIT = "deposit",
  LOGIN = "login",
  ANY = "any",
}

export enum QuestLevel {
  EASY = "easy",
  MEDIUM = "medium",
  HARD = "hard",
}

export enum GameRefType {
  PG_GAME = "PG",
  EVO_GAME = "EVO",
  JOKER_GAME = "JOKER",
  PNG_GAME = "PNG",
}

export interface BankReportItem {
  reportId: string;
  transactionDate: string;
  customerBankCode: BankCode | string | null;
  customerAccountName: string | null;
  customerAccountNumber: string | null;
  amount: number;
  pigBankCode: BankCode | null;
  pigAccountName: string | null;
  pigAccountNumber: string | null;
  bankPromptpayNo: string | null;
  channel: Channel;
  createdAt: string;
  transactionChannel: string;
  transactionType: BankTransactionType;
}

export interface BankReportItemTable extends BankReportItem {
  transactionTime: string;
  createdDate: string;
  createdTime: string;
}

export interface TicketManagementAPI {
  createdAt: string;
  questName: string | null;
  gameName: string | null;
  ticketType: string;
  ticketBefore: number;
  ticketUsed: number;
  ticketAfter: number;
  bigwintransactionUid: string;
  imageUrl: string;
  totalTicket: number;
}

export interface QuestManagementAPI {
  questUid: string;
  questName: string;
  questType: QuestType;
  questLevel: QuestLevel;
  reward: string;
  children: [
    {
      questUid: string | null;
      questName: string;
      gameName: string;
      gameRefType: string;
      questTypeEdit: string;
      questType: QuestType;
      questLevel: QuestLevel;
      reward: number;
    }
  ];
}

export interface WeeklyQuestManagementAPI {
  uid: string;
  gameRefType: string;
  gameId: string;
  gameName: string;
  questCode: string;
  questName: string;
  reward: number;
  questRules: QuestLevel;
}

export interface VipHistoryAPI<T> {
  turnOver: number;
  vipLevel: number;
  result: T[];
}

export interface VipHistoryResult {
  customerCode: string;
  tier: string;
  level: number;
  receivedAt: string;
  type: string;
  detail: string;
}