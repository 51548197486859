import { Space, Typography } from "antd"
import { BankCode, BankRow, BANK_TYPE } from "src/types"
import { formatDateTime } from "src/utils/date"
import { getBankFormat, getPhoneFormat } from "src/utils/textOperation/textOperation"
import colors from 'src/theme/colors.json';
import { AmountColumn, BankLogo, ColumnType, PhoneColumn } from ".."
import Status from "../Bank"
import Account from "../Bank/Account"
import { IsAutoStatus } from "../Bank/Status"

export type BankColumnField = 'bankAccount' | 'bank'
  | 'accountName' | 'accountNumber' | 'balance' | 'bankBalance' | 'limitAutoPerTransaction'
  | 'status' | 'autoTransaction' | 'autoWebTransaction' | 'promptpay' | 'remark' | 'verifyAt'
  | 'availableBalance' | 'limitTransfer' | 'transferedAmount';

export const BankColumns = (): Record<BankColumnField, ColumnType<BankRow>> => {
  return {
    bankAccount: {
      title: 'บัญชี PIGSPIN',
      dataIndex: 'bankAccount',
      key: 'bankAccount',
      render: (value: BankCode, bankRow: BankRow) => {
        return (
          <Space>
            <Account
              bankCode={bankRow.bankCode}
              bankName={bankRow.bankName}
              accountName={bankRow.accountName}
              accountNumber={bankRow.accountNumber}
            />
          </Space>
        )
      },
    },
    bank: {
      title: 'ธนาคาร',
      dataIndex: 'bankCode',
      key: 'bankCode',
      render: (value: BankCode) => {
        return (
          <Space>
            <BankLogo bankCode={value} showName />
          </Space>
        )
      },
    },
    accountName: {
      title: 'ชื่อบัญชี',
      dataIndex: 'accountName',
      key: 'accountName',
    },
    accountNumber: {
      title: 'เลขบัญชี',
      dataIndex: 'accountNumber',
      key: 'accountNumber',
      render: (value: string, bankRow: BankRow) => {
        const formatAccount = bankRow.bankCode === BankCode.TrueMoney ? getPhoneFormat : getBankFormat
        return (
          <div>{formatAccount(value)}</div>
        )
      },
    },
    balance: {
      title: 'จำนวนเงินในระบบ (THB)',
      dataIndex: 'balance',
      key: 'balance',
      width: 240,
      render: (value: number, record) => {
        if (record.type === BANK_TYPE.NONE) return null;
        return (
          <AmountColumn amount={value} />
        )
      },
    },
    bankBalance: {
      title: 'จำนวนเงินในบัญชีธนาคาร (THB)',
      dataIndex: 'bankBalance',
      key: 'bankBalance',
      width: 220,
      render: (value: number, record) => {
        if (record.type === BANK_TYPE.NONE) return null;
        const isManualWithdraw = record.type === BANK_TYPE.WITHDRAW && !record.isAuto && !record.isWebAuto;
        return (
          <AmountColumn amount={value} dashed={isManualWithdraw} />
        );
      },
    },
    limitAutoPerTransaction: {
      title: 'โอนอัตโนมัติ/ครั้ง (THB)',
      dataIndex: 'limitAutoPerTransaction',
      key: 'limitAutoPerTransaction',
      width: 180,
      render: (value: number) => {
        return (
          <AmountColumn amount={value} />
        )
      },
    },
    status: {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      width: 180,
      render: (value: number, row: BankRow) => {
        return (
          <Space>
            <Status type={row.status} />
          </Space>
        )
      },
    },
    autoTransaction: {
      title: 'ระบบอัตโนมัติ',
      dataIndex: 'status',
      key: 'isAuto',
      width: 120,
      render: (value: boolean, row: BankRow) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <IsAutoStatus enabled={row.isAuto} />
          </div>
        )
      },
    },
    autoWebTransaction: {
      title: 'ระบบอัตโนมัติ (หน้าบ้าน)',
      dataIndex: 'status',
      key: 'isWebAuto',
      width: 120,
      render: (value: boolean, row: BankRow) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <IsAutoStatus enabled={row.isWebAuto === true} />
          </div>
        )
      },
    },
    promptpay: {
      title: 'เบอร์พร้อมเพย์',
      dataIndex: 'promptpay',
      key: 'promptpay',
      width: 180,
      render: (value: string, row: BankRow) => {
        return (
          <Space>
            <PhoneColumn phoneNumber={value} />
          </Space>
        )
      },
    },
    remark: {
      title: 'หมายเหตุ',
      dataIndex: 'status',
      key: 'remark',
      render: (value: string, row: BankRow) => {
        return (
          <Space>
            <Status type={row.status} />
          </Space>
        )
      },
    },
    verifyAt: {
      title: 'ตรวจสอบล่าสุด',
      dataIndex: 'verifyAt',
      key: 'verifyAt',
      width: 150,
      render: (value: Date, row: BankRow) => {
        return (
          <div>
            <Space align="center">
              {row.verifyAt && formatDateTime(row.verifyAt)}
            </Space>
          </div>
        )
      },
    },
    availableBalance: {
      title: 'ยอดที่สามารถโอนได้ (THB)',
      dataIndex: 'availableBalance',
      key: 'availableBalance',
      width: 240,
      render: (value: number, record) => {
        if (record.type === BANK_TYPE.NONE) return null;
        const limitTransfer = record.limitTransfer || 0;
        const availableBalance = record.availableBalance || 0;
        const lessThan20PercentOflimit = availableBalance < (limitTransfer * 0.2)
        return (
          <Typography.Text style={{ color: lessThan20PercentOflimit ? colors["@red-7"] : '' }}>
            <AmountColumn amount={availableBalance} />
          </Typography.Text>
        )
      },
    },
    limitTransfer: {
      title: 'ลิมิตการทำรายการ (THB)',
      dataIndex: 'limitTransfer',
      key: 'limitTransfer',
      width: 240,
      render: (value: number, record) => {
        if (record.type === BANK_TYPE.NONE) return null;
        return (
          <AmountColumn amount={value} />
        )
      },
    },
    transferedAmount: {
      title: 'ทำรายการแล้ว (THB)',
      dataIndex: 'transferedAmount',
      key: 'transferedAmount',
      width: 240,
      render: (value: number, record) => {
        if (record.type === BANK_TYPE.NONE) return null;
        return (
          <AmountColumn amount={value} />
        )
      },
    },
  }
}
