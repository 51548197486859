import { AxiosRequestConfig } from 'axios';
import { BankCode, BANK_TYPE } from 'src/types';
import { pigspinBackofficeApiInstance } from "../../utils/api/AxiosInstance";
import { fetchWithJSON } from "../../utils/api/fetch";
import { BaseRequest, BaseResponse2 } from "../service.type";
import { BankAccountItem } from './type';


export type ReserveTransactionRequest = {
  token: string;
  transaction_uid: string;
};

export type ReserveDepositTransactionData = {

};

export type ReserveTransactionResponse = BaseResponse2<ReserveDepositTransactionData>;

export const reserveDepositTransaction = async (request: ReserveTransactionRequest): Promise<ReserveTransactionResponse> => {
  const requestConfig: AxiosRequestConfig = {
    url: 'v1/payment/deposit/reserve-transaction',
    method: 'POST',
    data: {
      transaction_uid: request.transaction_uid,
    },
    headers: {
      token: request.token,
    }
  };
  const result = await fetchWithJSON<ReserveTransactionResponse>(pigspinBackofficeApiInstance, requestConfig);
  return result;
};

export const reserveWithdrawTransaction = async (request: ReserveTransactionRequest): Promise<ReserveTransactionResponse> => {
  const requestConfig: AxiosRequestConfig = {
    url: 'v1/payment/withdraw/reserve-transaction',
    method: 'POST',
    data: {
      transaction_uid: request.transaction_uid,
    },
    headers: {
      token: request.token,
    }
  };
  const result = await fetchWithJSON<ReserveTransactionResponse>(pigspinBackofficeApiInstance, requestConfig);
  return result;
};



export type CancelReserveTransactionRequest = {
  token: string;
  transaction_uid: string;
};

export type CancelReserveDepositTransactionData = {

};

export type CancelReserveTransactionResponse = BaseResponse2<ReserveDepositTransactionData>;

export const cancelReserveDepositTransaction = async (request: ReserveTransactionRequest): Promise<ReserveTransactionResponse> => {
  const requestConfig: AxiosRequestConfig = {
    url: 'v1/payment/deposit/cancel-reserve-transaction',
    method: 'POST',
    data: {
      transaction_uid: request.transaction_uid,
    },
    headers: {
      token: request.token,
    }
  };
  const result = await fetchWithJSON<ReserveTransactionResponse>(pigspinBackofficeApiInstance, requestConfig);
  return result;
};

export const cancelReserveWithdrawTransaction = async (request: ReserveTransactionRequest): Promise<ReserveTransactionResponse> => {
  const requestConfig: AxiosRequestConfig = {
    url: 'v1/payment/withdraw/cancel-reserve-transaction',
    method: 'POST',
    data: {
      transaction_uid: request.transaction_uid,
    },
    headers: {
      token: request.token,
    }
  };
  const result = await fetchWithJSON<ReserveTransactionResponse>(pigspinBackofficeApiInstance, requestConfig);
  return result;
};

export type PaymentValidateRequest = {
  token: string;
  bankCode: BankCode;
  bankAccountName: string;
  bankAccountNumber: string;
}

export type PaymentValidateData = {
  actual_bank_account_name: string;
  actual_bank_account_name_language: string;
  is_valid: boolean;
  user_bank_account_name: string;
  user_bank_account_number: string;
  user_bank_code: BankCode;

}

export type PaymentValidateResponse = BaseResponse2<PaymentValidateData>

export const PaymentValidate = async (request: PaymentValidateRequest): Promise<PaymentValidateResponse> => {
  const requestConfig: AxiosRequestConfig = {
    url: 'v1/payment/validate',
    method: 'POST',
    data: {
      bank_code: request.bankCode,
      bank_account_number: request.bankAccountNumber,
      bank_account_name: request.bankAccountName,
    },
    headers: {
      token: request.token,
    }
  };
  return fetchWithJSON<PaymentValidateResponse>(pigspinBackofficeApiInstance, requestConfig);
};

export type PaymentWithdrawRequest = BaseRequest & {
  bank_code: string;
  bank_account_number: string;
  bank_account_name: string;
  transfered_at: string;
  transaction_uid: string;
};

export type PaymentWithdrawData = {

}

export type PaymentWithdrawResponse = BaseResponse2<PaymentValidateData>;

export const PostWithdraw = async (request: PaymentWithdrawRequest): Promise<PaymentWithdrawResponse> => {
  const requestConfig: AxiosRequestConfig = {
    url: 'v1/payment/withdraw/manual-bank-account',
    method: 'POST',
    headers: {
      token: request.token,
    },
    data: {
      bank_code: request.bank_code,
      bank_account_number: request.bank_account_number,
      bank_account_name: request.bank_account_name,
      transferred_at: request.transfered_at,
      transaction_uid: request.transaction_uid,
    },
  };
  return fetchWithJSON<PaymentWithdrawResponse>(pigspinBackofficeApiInstance, requestConfig);

}

export type RetryAutoWithdrawRequest = BaseRequest & {
  transactionId: string;
  groupBankCode: BankCode;
};

export type RetryAutoWithdrawData = {
  amount_transferred: string;
  from_account_name: string;
  from_account_number: string;
  from_bank_code: BankCode
  qrstring: string;
  ref: string;
  remaining_balance: number;
  to_account_name: string;
  to_account_number: string;
  to_bank_code: BankCode;
};

export type RetryAutoWithdrawResponse = BaseResponse2<RetryAutoWithdrawData | undefined>;

export const PostRetryAutoWithdraw = async (request: RetryAutoWithdrawRequest): Promise<RetryAutoWithdrawResponse> => {
  const requestConfig: AxiosRequestConfig = {
    url: 'v1/payment/withdraw',
    method: 'POST',
    headers: {
      token: request.token,
    },
    data: {
      transaction_uid: request.transactionId,
      bgb_group_bank_code: request.groupBankCode,
    },
    timeout: 15000,
  };
  return fetchWithJSON<RetryAutoWithdrawResponse>(pigspinBackofficeApiInstance, requestConfig).catch((e) => ({ ...e.response.data })
  );
}

export type RefundRequest = BaseRequest & {
  bank_code: string;
  bank_account_number: string;
  bank_account_name: string;
  transaction_uid: string;
  transferred_at: string;
};

export type RefundData = {

};

export type RefundResponse = BaseResponse2<RefundData>;

export const PostRefund = async (request: RefundRequest): Promise<RefundResponse> => {
  const requestConfig: AxiosRequestConfig = {
    url: 'v1/payment/deposit/refund-transactions',
    method: 'POST',
    headers: {
      token: request.token,
    },
    data: {
      bank_code: request.bank_code,
      transferred_at: request.transferred_at,
      transaction_uid: request.transaction_uid,
      bank_account_name: request.bank_account_name,
      bank_account_number: request.bank_account_number,
    },
  };
  return fetchWithJSON<RefundResponse>(pigspinBackofficeApiInstance, requestConfig);
}

export type RejectWithdrawRequest = BaseRequest & {
  transaction_uid: string;
}

export type RejectWithdrawData = {}

export type RejectWithdrawResponse = BaseResponse2<RejectWithdrawData>;


export const PostRejectWithdraw = async (request: RejectWithdrawRequest): Promise<RejectWithdrawResponse> => {
  const requestConfig: AxiosRequestConfig = {
    url: 'v1/payment/withdraw/reject-transactions',
    method: 'POST',
    headers: {
      token: request.token,
    },
    data: {
      transaction_uid: request.transaction_uid,
    },
  };
  return fetchWithJSON<RejectWithdrawResponse>(pigspinBackofficeApiInstance, requestConfig);
}


export type CreateBankRequest = BaseRequest & {
  bank_type: BANK_TYPE;
  bank_code: string;
  bank_account_name: string;
  bank_account_number: string;
  phone_number: string;
  true_auto_token: string;
  autowithdraw_device_id?: string,
  autowithdraw_pin?: number,
  autowithdraw_minimum_amount_noti?: number,
  limit_auto_per_transaction?: number,
}

export type CreateBankData = {}
export type ErrorBankData = {
  is_duplicate_bank: boolean;
  is_duplicate_phone: boolean;
}
export type CreateBankResponse = BaseResponse2<CreateBankData & ErrorBankData>;

export const PostCreateBank = (request: CreateBankRequest): Promise<CreateBankResponse> => {
  const requestConfig: AxiosRequestConfig = {
    url: 'v1/payment/manage-bank/bgb-bank',
    method: 'POST',
    headers: {
      token: request.token,
    },
    data: {
      bank_type: request.bank_type,
      bank_code: request.bank_code,
      bank_account_name: request.bank_account_name,
      bank_account_number: request.bank_account_number,
      phone_number: request.phone_number,
      true_auto_token: request.true_auto_token,
      autowithdraw_device_id: request.autowithdraw_device_id,
      autowithdraw_pin: request.autowithdraw_pin,
      autowithdraw_minimum_amount_noti: request.autowithdraw_minimum_amount_noti,
      limit_auto_per_transaction: request.limit_auto_per_transaction,
    },
  };
  return fetchWithJSON<CreateBankResponse>(pigspinBackofficeApiInstance, requestConfig);
}

export type EditBankRequest = BaseRequest & {
  bank_type: string;
  bgb_bank_code_uid: string;
  status: string;
  phone_number: string;
  true_auto_token: string;
  force_pending_to?: Date;
  autowithdraw_device_id?: string;
  autowithdraw_pin?: number;
  autowithdraw_minimum_amount_noti?: number;
  limit_auto_per_transaction?: number;
  cooldown_number?: number;
  cooldown_minute?: number;
  daily_withdrawal_limit?: number;
  priority_order?: number;
};

export type DepositBankRequest = EditBankRequest & {
  is_auto?: boolean;
  is_api?: boolean;
  is_sms?: boolean;
}

export type WithdrawBankRequest = EditBankRequest & {
  is_auto_bo?: boolean;
  is_auto_web?: boolean;
}

export type EditBankData = {};
export type EditBankResponse = BaseResponse2<EditBankData & ErrorBankData>;

export const PutEditBank = (request: DepositBankRequest | WithdrawBankRequest): Promise<EditBankResponse> => {
  const { token, ...restRequest } = request;
  const requestConfig: AxiosRequestConfig = {
    url: 'v1/payment/manage-bank/bgb-bank',
    method: 'PUT',
    headers: {
      token: request.token,
    },
    data: {
      ...restRequest,
    },
  };
  return fetchWithJSON<EditBankResponse>(pigspinBackofficeApiInstance, requestConfig);
}
